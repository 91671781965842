<template>
  <UltraBox v-if="highlight">
    <UltraColText columns="two" data-aos="zoom-in-right">
      <h3 class="fn-color secondary mb-20" v-if="highlight.title">{{ highlight.title }}</h3>
      <p v-if="highlight.description">{{ highlight.description }}</p>
    </UltraColText>
    <UltraColText columns="two" data-aos="zoom-in-left" v-if="highlight.image">
      <UltraLink :action="highlight.action" v-if="highlight.action">
        <img class="img-rounded" :src="highlight.image" alt="image" />
        <i class="icon-play-fill ultra-large img-icon" v-if="highlight.url === '#video'"></i>
      </UltraLink>
      <img class="img-rounded" :src="highlight.image" alt="image" v-else />
    </UltraColText>
  </UltraBox>
</template>

<script>
import UltraBox from "@/components/UltraBox";
import UltraColText from "@/components/UltraColText";
import UltraLink from "@/components/UltraLink";

export default {
  name: "BlockHighlight",
  components: {UltraLink, UltraColText, UltraBox},
  computed: {
    highlight() {
      return this.$store.getters['highlight'];
    },
  },
}
</script>

<style scoped>

</style>