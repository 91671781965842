<template>
  <UltraBox class="block-comments" v-if="comments">
    <header class="block-comments-header" data-aos="zoom-in-up">
      <h2 class="fn-color secondary tx-uppercase">Depoimentos</h2>
    </header>
    <section class="block-comments-section gb-section">
      <Carousel :itemsToShow="3" :wrapAround="true" :autoplay="7000">
        <Slide v-for="item in comments" :key="item.id">
          <UltraCardComment :comment="item" />
        </Slide>
        <template #addons>
          <Pagination />
          <Navigation />
        </template>
      </Carousel>
    </section>
  </UltraBox>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UltraBox from "@/components/UltraBox";
import UltraCardComment from "@/components/UltraCardComment";

export default {
  name: "BlockComments",
  components: {
    UltraCardComment,
    UltraBox,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  computed: {
    comments() {
      return this.$store.getters['comments'];
    }
  },
}
</script>

<style scoped>
.block-comments {
  text-align: center;
}
.block-comments-header {
  width: 100%;
  margin-bottom: 40px;
}
.block-comments-section {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>