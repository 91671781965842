<template>
  <div class="ultra-btn bg-color" :class="[size, color]" @click.prevent="execAction(action)">
    <div class="ultra-btn-icon" v-if="action && action.icon">
      <i :class="`icon-${action.icon} small`" />
    </div>
    <div class="ultra-btn-text" v-if="action && action.text">
      {{ action ? action.text : '' }}
    </div>
    <div class="ultra-btn-text" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraBtn",
  mixins: [mixins],
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    action: {
      type: Object,
      required: false,
    },
  },
}
</script>

<style scoped>
.ultra-btn {
  font-size: 1.2rem;
  line-height: 1.2rem;
  min-height: 60px;
  font-weight: 600;
  padding: 20px 30px;
  border: none;
  color: currentColor;
  border-radius: 30px;
  transition: all ease-in-out .3s;
  cursor: pointer;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
}
.ultra-btn.small {
  font-size: 1.2rem;
  line-height: 1.2rem;
  min-height: 40px;
  font-weight: 400;
  padding: 10px 25px;
  border: none;
  color: currentColor;
  border-radius: 10px;
  transition: filter ease-in-out .3s;
  cursor: pointer;
}
.ultra-btn.textSmall {
  font-size: .9rem;
  line-height: .9rem;
  text-transform: none;
}
.ultra-btn-icon {
  margin-right: 10px;
  margin-top: 1px;
  margin-left: -10px;
}
.ultra-btn-text {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: center;
}

.ultra-btn:hover {
  filter: brightness(1.40);
  transform: translate3d(0, -2px, 0);
  transition: all ease-in-out .3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
}
</style>