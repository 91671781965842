<template>
  <UltraHeader ref="header" />
  <UltraSlide ref="slide" />
  <BlockHighlight ref="highlight" />
  <BlockModules ref="modules" />
  <BlockCustomization ref="customization" />
  <BlockMovie ref="movie" />
  <BlockDrops ref="drops" />
  <BlockOutdoor ref="outdoor" />
  <BlockComments ref="comments" />
  <BlockFooter ref="footer" />
  <BlockFixedButtons />
  <UltraModal />
</template>

<script>
import AOS from 'aos';
import mixins from "@/mixins";
import UltraHeader from "@/components/UltraHeader";
import UltraSlide from "@/components/UltraSlide";
import prepare from "@/datasource";
import BlockHighlight from "@/blocks/BlockHighlight";
import BlockModules from "@/blocks/BlockModules";
import BlockMovie from "@/blocks/BlockMovie";
import BlockDrops from "@/blocks/BlockDrops";
import BlockOutdoor from "@/blocks/BlockOutdoor";
import BlockComments from "@/blocks/BlockComments";
import BlockFooter from "@/blocks/BlockFooter";
import BlockFixedButtons from "@/blocks/BlockFixedButtons";
import UltraModal from "@/components/UltraModal";
import BlockCustomization from "@/blocks/BlockCustomizations";

export default {
  name: 'App',
  mixins: [mixins],
  mounted() {
    AOS.init();
    this.initStore();
    this.initScrollController();
  },
  beforeUnmount() {
    this.destroyScrollController();
  },
  methods: {
    async initStore() {
      const datasource = await prepare();
      this.$store.commit('loader', datasource);
    },
    initScrollController() {
      window.addEventListener('canScroll', this.canScroll);
    },
    destroyScrollController() {
      window.removeEventListener('canScroll', this.canScroll);
    },
  },
  components: {
    BlockCustomization,
    UltraModal,
    BlockFixedButtons,
    BlockFooter,
    BlockComments,
    BlockOutdoor,
    BlockDrops,
    BlockMovie,
    BlockModules,
    BlockHighlight,
    UltraSlide,
    UltraHeader,
  },
}
</script>

<style>
@import "@/assets/app.css";
</style>