<template>
  <footer class="block-footer gb-section">
    <div class="block-footer-wrapper gb-wrapper">
      <p>Um produto</p>
      <img src="@/assets/images/softline-logo.png" alt="SoftLine" />
    </div>
  </footer>
  <UltraPrivacyMessage />
</template>

<script>
import UltraPrivacyMessage from "@/components/UltraPrivacyMessage";
export default {
  name: "BlockFooter",
  components: {UltraPrivacyMessage}

}
</script>

<style scoped>
.block-footer {
  background-color: rgb(220, 220, 220);
}
.block-footer-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 0 150px 0;
  border-top: 1px solid var(--color-black);
}
</style>