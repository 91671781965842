<template>
  <section class="ultra-slide gb-section" v-if="top">
    <img :src="top.image" alt="slide-top" v-if="top.image" data-aos="slide-down" />
    <div class="ultra-slide-wrapper gb-wrapper">
      <div class="ultra-slide-card" data-aos="zoom-in">
        <h1 class="AmpleSoft mb-20 fn-color secondary" v-if="top.title">{{ top.title }}</h1>
        <p class="mb-20" v-if="top.description">{{ top.description }}</p>
        <UltraBtn :action="top.action" v-if="top.action" />
      </div>
    </div>
  </section>
  <UltraDivider />
</template>

<script>
import UltraBtn from "@/components/UltraBtn";
import UltraDivider from "@/components/UltraDivider";

export default {
  name: "UltraSlide",
  components: {UltraDivider, UltraBtn},
  computed: {
    top() {
      return this.$store.getters['top'];
    },
  },
}
</script>

<style scoped>
.ultra-slide {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: var(--color-white);
  color: var(--color-white);
}
.ultra-slide > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}
.ultra-slide-wrapper {
  padding: 90px 0 0 0;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}
.ultra-slide-card {
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .ultra-slide-card {
    width: 100%;
    text-align: center;
  }
}
</style>